// Mock data for FE demonstration and testing

import MightyBearsIcon from '../mock/mighty_bears_icon.jpeg';
import MadDogsIcon from '../mock/mad_dogs_icon.jpeg';
import BraveBoarsIcon from '../mock/brave_boars_icon.jpeg';
import FatCatsIcon from '../mock/fat_cats_icon.jpeg';
import CaliforniaCupIcon from '../mock/california_cup_icon.jpeg';
import FloridaCupIcon from '../mock/florida_cup_icon.jpeg';
import TexasCupIcon from '../mock/texas_cup_icon.jpeg';
import NewYorkCupIcon from '../mock/new_york_cup_icon.jpeg';
import Girl1Icon from '../mock/girl1_icon.jpeg';
import Girl2Icon from '../mock/girl2_icon.jpeg';
import Girl3Icon from '../mock/girl3_icon.jpeg';
import Girl4Icon from '../mock/girl4_icon.jpeg';
import Coach1Icon from '../mock/coach1_icon.jpeg';
import Coach2Icon from '../mock/coach2_icon.jpeg';
import IdImage from '../mock/id.jpeg';
import BirthCertificateImage from '../mock/birth_certificate.jpeg';
import SchoolReportCardImage from '../mock/school_report_card.jpeg';

// id is also route
export const teams = {
  'mighty-bears': {
    name: 'Mighty bears',
    icon: MightyBearsIcon,
    location: 'Los Angeles, CA',
    sport: 'Football (7v7)',
    division: 'Boys, 12u',
    coach: 'alexander-smith',
    players: [
      'hayden-debusk',
      'jelena-pivaroff',
      'keira-sherman',
      'leah-vanderveen',
    ],
  },
  'mad-dogs': {
    name: 'Mad dogs',
    icon: MadDogsIcon,
    location: 'New York, NY',
    sport: 'Football (5v5)',
    division: 'Girls, 12u',
    coach: 'alexander-smith',
    players: [
      'hayden-debusk',
      'jelena-pivaroff',
      'keira-sherman',
      'leah-vanderveen',
    ],
  },
  'brave-boars': {
    name: 'Brave boars',
    icon: BraveBoarsIcon,
    location: 'Chicago, IL',
    sport: 'Football (5v5)',
    division: 'Boys, 16u',
    coach: 'benjamin-johnson',
    players: [
      'hayden-debusk',
      'jelena-pivaroff',
      'keira-sherman',
      'leah-vanderveen',
    ],
  },
  'fat-cats': {
    name: 'Fat cats',
    icon: FatCatsIcon,
    location: 'Philadelphia, PA',
    sport: 'Football (Flag)',
    division: 'Girls, 16u',
    coach: 'benjamin-johnson',
    players: [
      'hayden-debusk',
      'jelena-pivaroff',
      'keira-sherman',
      'leah-vanderveen',
    ],
  },
};

// tournaments statuses enum
export const TournStatuses = {
  ELIGIBLE: 'eligible',
  UNPAID: 'unpaid',
  PENDING: 'pending',
};

// id is also route
export const tournaments = {
  'california-cup': {
    icon: CaliforniaCupIcon,
    name: 'California cup',
    location: 'Sacramento, CA',
    dateFrom: '09.01.2024',
    dateTo: '09.03.2024',
    sport: 'Football (Flag)',
    type: "Boy's tournament",
    division: '8u to 18u',
    teams: {
      'mighty-bears': {
        paid: 450,
        status: TournStatuses.ELIGIBLE,
      },
      'brave-boars': {
        paid: 0,
        status: TournStatuses.UNPAID,
      },
    },
  },
  'texas-cup': {
    icon: TexasCupIcon,
    name: 'Texas cup',
    location: 'Austin, TX',
    dateFrom: '10.15.2024',
    dateTo: '10.20.2024',
    sport: 'Football (Flag)',
    type: "Girl's tournament",
    division: '8u to 18u',
    teams: {
      'mighty-bears': {
        paid: 150,
        status: TournStatuses.UNPAID,
      },
      'fat-cats': {
        paid: 0,
        status: TournStatuses.ELIGIBLE,
      },
    },
  },
  'florida-cup': {
    icon: FloridaCupIcon,
    name: 'Florida cup',
    location: 'Tallahassee, FL',
    dateFrom: '11.10.2024',
    dateTo: '11.12.2024',
    sport: 'Football (7v7)',
    type: "Boy's, Girl's, Coed tournament",
    division: '12u to Adult',
    teams: {
      'mighty-bears': {
        paid: 0,
        status: TournStatuses.PENDING,
      },
      'brave-boars': {
        paid: 0,
        status: TournStatuses.UNPAID,
      },
      'mad-dogs': {
        paid: 0,
        status: TournStatuses.PENDING,
      },
    },
  },
  'new-york-cup': {
    icon: NewYorkCupIcon,
    name: 'New York cup',
    location: 'Albany, NY',
    dateFrom: '12.12.2024',
    dateTo: '12.13.2024',
    sport: 'Football (5v5)',
    type: "Boy's, Girl's, Coed tournament",
    division: '12u to Adult',
    teams: {
      'fat-cats': {
        paid: 450,
        status: TournStatuses.ELIGIBLE,
      },
      'mad-dogs': {
        paid: 0,
        status: TournStatuses.UNPAID,
      },
    },
  },
};

export const purchases = [
  {
    date: '01.01.2024',
    payer: 'Alexander Smith',
    description: 'Mighty bears for California cup',
    amount: 450,
  },
  {
    date: '01.15.2024',
    payer: 'Benjamin Johnson',
    description: 'Mad dogs for Texas cup',
    amount: 450,
  },
  {
    date: '02.01.2024',
    payer: 'Christopher Brown',
    description: 'Brave boars for Florida cup',
    amount: 450,
  },
  {
    date: '02.10.2024',
    payer: 'Daniel Anderson',
    description: 'Fat cats for New York cup',
    amount: 450,
  },
];

export const players = {
  'hayden-debusk': {
    name: 'Hayden DeBusk',
    icon: Girl1Icon,
    team: 'mighty-bears',
    dateOfBirth: '03.23.2009',
    cardNum: '8793205164',
    cardExp: '01.01.2025',
    phone: '+1 (555) 123-4567',
    email: 'test@test.com',
    idImage: IdImage,
    birthCertificateImage: null,
    schoolReportCardImage: SchoolReportCardImage,
  },
  'jelena-pivaroff': {
    name: 'Jelena Pivaroff',
    icon: Girl2Icon,
    team: 'mad-dogs',
    dateOfBirth: '09.08.2009',
    cardNum: '6432891750',
    cardExp: '01.01.2025',
    phone: '+1 (555) 123-4567',
    email: 'test@test.com',
    idImage: IdImage,
    birthCertificateImage: BirthCertificateImage,
    schoolReportCardImage: SchoolReportCardImage,
  },
  'keira-sherman': {
    name: 'Keira Sherman',
    team: 'brave-boars',
    icon: Girl3Icon,
    dateOfBirth: '08.10.2008',
    cardNum: null,
    cardExp: null,
    phone: '+1 (555) 123-4567',
    email: 'test@test.com',
    idImage: IdImage,
    birthCertificateImage: BirthCertificateImage,
    schoolReportCardImage: null,
  },
  'leah-vanderveen': {
    name: 'Leah Vanderveen',
    team: 'fat-cats',
    icon: Girl4Icon,
    dateOfBirth: '6.04.2008',
    cardNum: '1978350246',
    cardExp: '01.01.2025',
    phone: '+1 (555) 123-4567',
    email: 'test@test.com',
    idImage: IdImage,
    birthCertificateImage: BirthCertificateImage,
    schoolReportCardImage: SchoolReportCardImage,
  },
};

export const coaches = {
  'alexander-smith': {
    name: 'Alexander Smith',
    icon: Coach1Icon,
    phone: '+1 (555) 123-4567',
    email: 'test@test.com',
    idImage: IdImage,
    teams: ['mighty-bears', 'mad-dogs'],
  },
  'benjamin-johnson': {
    name: 'Benjamin Johnson',
    icon: Coach2Icon,
    phone: '+1 (555) 123-4567',
    email: 'test@test.com',
    idImage: IdImage,
    teams: ['brave-boars', 'fat-cats'],
  },
};

// users statuses enum
export const UsersRoles = {
  ADMIN: 'admin',
  COACH: 'coach',
  PLAYER: 'player',
};

export const users = {
  'alexander-smith': {
    email: 'coach@test.com',
    name: 'Alexander Smith',
    role: UsersRoles.COACH,
    redirect: '/coaches/alexander-smith',
  },
  'keira-sherman': {
    email: 'player@test.com',
    name: 'Keira Sherman',
    role: UsersRoles.PLAYER,
    redirect: '/players/keira-sherman',
  },
  'bruce-kelber': {
    email: 'admin@test.com',
    name: 'Bruce Kelber',
    role: UsersRoles.ADMIN,
    redirect: '/admin',
  },
};
