// Tournament page

import { useLoaderData } from 'react-router-dom';
import Layout from '../components/Layout';
import TournamentCard from '../components/TournamentCard';

export default function PageTournament() {
  const tournament = useLoaderData();

  return (
    <Layout className='page-tournament'>
      <TournamentCard tournament={tournament} />
    </Layout>
  );
}
