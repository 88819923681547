// User element in header including menu

import { useState, useContext } from 'react';
import { Context } from '../App';
import ContextMenu from './ContextMenu';
import './User.scss';

const userMenuOptions = ['Log out'];

export default function User() {
  const [showUserMenu, setShowUserMenu] = useState(false);
  const { user, setUser } = useContext(Context);

  if (!user) return;

  const onUserClick = (e) => {
    setShowUserMenu(true);
  };

  const onUserMenuSelect = (selection) => {
    switch (selection) {
      case 0:
        setUser(null);
        break;

      default:
        console.log(userMenuOptions[selection]);
        break;
    }
  };

  return (
    <div className='user' onClick={onUserClick}>
      <p className='icon'>&#xf007;</p>
      <p className='name upper font-medium'>{user.name}</p>
      <ContextMenu
        show={showUserMenu}
        onClose={() => setShowUserMenu(false)}
        options={userMenuOptions}
        onSelect={onUserMenuSelect}
        className='user-menu'
      />
    </div>
  );
}
