// Tournament details interface

import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { Context } from '../App';
import { UsersRoles } from '../mock/data';
import moment from 'moment';
import TeamsList from './TeamsList';
import CardTemplate from './CardTemplate';
import './TournamentCard.scss';

export default function TournamentCard({ tournament }) {
  const navigate = useNavigate();
  const { user } = useContext(Context);

  const { id, icon, name, location, dateFrom, dateTo, sport, type, division } =
    tournament;

  const userIsAdmin = user.role === UsersRoles.ADMIN;

  return (
    <CardTemplate
      header='Tournament'
      className='tournament-card'
      cardBtnText='Back'
      cardBtnOnClick={() => navigate(-1)}
      icon={icon}
      name={name}
      firstCol={
        <>
          <p className='fa-location font-16'>{location}</p>
          <p className='fa-calendar font-16'>
            {moment(dateFrom).format('MMM D, YY')} -{' '}
            {moment(dateTo).format('MMM D, YY')}
          </p>
        </>
      }
      secondCol={
        <>
          <p className='font-16'>
            <span className='gray-500 font-medium'>Sport:</span> {sport}
          </p>
          <p className='font-16'>
            <span className='gray-500 font-medium'>Type:</span> {type}
          </p>
          <p className='font-16'>
            <span className='gray-500 font-medium'>Division:</span> {division}
          </p>
        </>
      }
      deleteBtn={userIsAdmin}
      editBtn={userIsAdmin}
    >
      <div className='block teams-list-wrap'>
        <TeamsList tournamentId={id} />
      </div>
    </CardTemplate>
  );
}
