// Root component with routing definition

import { useState, createContext, useContext } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  Outlet,
} from 'react-router-dom';
import PageLogin from './pages/PageLogin';
import PageAdmin from './pages/PageAdmin';
import PageTournament from './pages/PageTournament';
import PageTeam from './pages/PageTeam';
import PagePlayer from './pages/PagePlayer';
import PageCoach from './pages/PageCoach';
import { tournaments, teams, players, coaches } from './mock/data';

export const Context = createContext();

const PrivateRoutes = () => {
  const { user } = useContext(Context);
  return user ? <Outlet /> : <Navigate to='/login' />;
};

const router = createBrowserRouter([
  { path: '/login', element: <PageLogin /> },
  {
    element: <PrivateRoutes />,
    children: [
      { path: '/admin', element: <PageAdmin /> },
      {
        path: '/tournaments/:tournamentId',
        element: <PageTournament />,
        loader: async ({ params }) => {
          const id = params.tournamentId;
          const tourn = tournaments[id];

          if (tourn) return { id, ...tourn };
          throw new Response('Not Found', { status: 404 });
        },
      },
      {
        path: '/teams/:teamId',
        element: <PageTeam />,
        loader: async ({ params }) => {
          const id = params.teamId;
          const team = teams[id];

          if (team) return { id, ...team };
          throw new Response('Not Found', { status: 404 });
        },
      },
      {
        path: '/players/:playerId',
        element: <PagePlayer />,
        loader: async ({ params }) => {
          const id = params.playerId;
          const player = players[id];

          if (player) return { id, ...player };
          throw new Response('Not Found', { status: 404 });
        },
      },
      {
        path: '/coaches/:coachId',
        element: <PageCoach />,
        loader: async ({ params }) => {
          const id = params.coachId;
          const coach = coaches[id];

          if (coach) return { id, ...coach };
          throw new Response('Not Found', { status: 404 });
        },
      },
    ],
  },
  { path: '*', element: <Navigate to='/login' replace /> },
]);

function App() {
  const [user, setUser] = useState(null);

  return (
    <Context.Provider
      value={{
        user,
        setUser,
      }}
    >
      <RouterProvider router={router} />
    </Context.Provider>
  );
}

export default App;
