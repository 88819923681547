// Common snippet for TeamsList and TournamentsList

import { TournStatuses, UsersRoles } from '../mock/data';
import './TeamTournStatusPaid.scss';

function Status({ status, role, className = '' }) {
  const isCoach = role === UsersRoles.COACH;

  switch (status) {
    case TournStatuses.ELIGIBLE:
      return (
        <p className={'success upper font-medium ' + className}>{status}</p>
      );
    case TournStatuses.UNPAID:
      if (isCoach)
        return (
          <div className={className}>
            <button
              className='small bw'
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              Pay
            </button>
          </div>
        );
      return (
        <p className={'danger upper font-medium ' + className}>{status}</p>
      );
    case TournStatuses.PENDING:
      if (isCoach)
        return (
          <div className={className}>
            <p>Invitation received</p>
            <div className='btn-wrap'>
              <button
                className='small bw'
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                Accept
              </button>
              <button
                className='small danger'
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                Reject
              </button>
            </div>
          </div>
        );
      return <p className={'upper font-medium ' + className}>{status}</p>;
    default:
      return (
        <div className={className}>
          <button
            className='small bw'
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            Invite
          </button>
        </div>
      );
  }
}

export default function TeamTournStatusPaid({ status, paid, role }) {
  const isCoach = role === UsersRoles.COACH;
  const isPending = status === TournStatuses.PENDING;

  return (
    <div className='team-tourn-status-paid'>
      <Status
        status={status}
        role={role}
        className={'status' + (isPending && isCoach ? ' pending' : '')}
      />
      <p
        className={
          'paid font-medium' + (isPending && isCoach ? ' pending' : '')
        }
      >
        {'$' + (paid ? paid : 0)}
      </p>
    </div>
  );
}
