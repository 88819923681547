// Team page

import { useLoaderData } from 'react-router-dom';
import Layout from '../components/Layout';
import CoachCard from '../components/CoachCard';

export default function PageCoach() {
  const coach = useLoaderData();

  return (
    <Layout className='page-coach'>
      <CoachCard coach={coach} />
    </Layout>
  );
}
