// Component to show images

import { useState, useRef, useEffect } from 'react';
import ImageModal from './ImageModal';
import { Link } from 'react-router-dom';
import './Gallery.scss';

export default function Gallery({ images }) {
  const [showImg, setShowImg] = useState(null);
  const [files, setFiles] = useState([]);
  const [uploadedFilesUrls, setUploadedFilesUrls] = useState([]);
  const inputFileRef = useRef([]);

  useEffect(() => {
    const revokeFiles = () => {
      uploadedFilesUrls.forEach((url) => {
        if (url) URL.revokeObjectURL(url);
      });
    };

    revokeFiles();
    setUploadedFilesUrls(
      files.map((file) => (file ? URL.createObjectURL(file) : file))
    );

    return () => {
      revokeFiles();
    };
  }, [files]);

  return (
    <div className='gallery'>
      {images.map((img, i) => (
        <div
          className='image-wrap pointer'
          key={i}
          onClick={
            img.image || uploadedFilesUrls[i]
              ? () => setShowImg(i)
              : () => inputFileRef.current[i].click()
          }
        >
          {(img.image || uploadedFilesUrls[i]) && (
            <div className='image-dwnload-btn'>
              <div
                className='bg-image wh-256'
                style={{
                  backgroundImage: `url(${img.image ?? uploadedFilesUrls[i]})`,
                }}
              />
              <Link
                to={img.image ?? uploadedFilesUrls[i]}
                target='_blank'
                onClick={(e) => e.stopPropagation()}
                className='button bw small'
                download={(img.image ?? uploadedFilesUrls[i]).split('/').pop()}
              >
                Download
              </Link>
            </div>
          )}
          {!img.image && !uploadedFilesUrls[i] && (
            <div className='wh-256 upload'>
              <h6>
                File
                <br />
                missing
              </h6>
              <button className='small bw'>Upload</button>
              <input
                type='file'
                accept='image/*'
                onChange={(e) =>
                  setFiles((files) => {
                    files[i] = e.target.files[0];
                    return [...files];
                  })
                }
                ref={(e) => (inputFileRef.current[i] = e)}
              />
            </div>
          )}
          <p className='title font-16'>{img.title}</p>
        </div>
      ))}
      <ImageModal
        show={showImg !== null}
        image={images[showImg]?.image ?? uploadedFilesUrls[showImg]}
        onClose={() => setShowImg(null)}
      />
    </div>
  );
}
