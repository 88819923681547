// Tabs interface component

import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Tabs.scss';

export default function Tabs({ className, tabs }) {
  const { hash } = useLocation();
  const tabsHashIndex = tabs.findIndex((tab) => tab.hash === hash);

  const [active, setActive] = useState(
    tabsHashIndex === -1 ? 0 : tabsHashIndex
  );
  const navigate = useNavigate();

  return (
    <div className={'tabs' + (className ? ' ' + className : '')}>
      <ul className='tabs-header'>
        {tabs.map((tab, i) => (
          <li
            key={i}
            className={'tabs-header-item' + (active === i ? ' active' : '')}
            onClick={() => {
              navigate(tab.hash, { replace: true });
              setActive(i);
            }}
          >
            {tab.title}
          </li>
        ))}
      </ul>
      {tabs[active].component}
    </div>
  );
}
