// Admin panel page

import Layout from '../components/Layout';
import AdminPanelCard from '../components/AdminPanelCard';

export default function PageAdmin() {
  return (
    <Layout className='page-admin'>
      <AdminPanelCard />
    </Layout>
  );
}
