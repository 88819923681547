// Custom select element

import { useState } from 'react';
import './Select.scss';

export default function Select({ options, value, onChange }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className='select'>
      <div className='selected-option' onClick={() => setIsOpen(!isOpen)}>
        <p className='value'>{value}</p>
        <p className='arrow'>{isOpen ? '\uf077' : '\uf078'}</p>
      </div>
      <ul className={'options' + (isOpen ? ' open' : '')}>
        {options.map((option, index) => (
          <li
            key={index}
            onClick={() => {
              onChange(option);
              setIsOpen(false);
            }}
          >
            {option}
          </li>
        ))}
      </ul>
    </div>
  );
}
