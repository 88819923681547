// Purchases list

import moment from 'moment';
import { Link } from 'react-router-dom';
import { purchases } from '../mock/data';
import './PurchasesList.scss';

function Col12({ date, payer }) {
  return (
    <>
      <p className='col-1'>{moment(date).format('MMM D, YYYY')}</p>
      <p className='col-2'>{payer}</p>
    </>
  );
}

export default function PurchasesList() {
  return (
    <div className='purchases-list'>
      <ul className='header tab-header'>
        <li className='col-1'>Date</li>
        <li className='col-2'>Payer</li>
        <li className='col-12-xl'>Date, Payer</li>
        <li className='col-3'>Description</li>
        <li className='col-4'>Amount</li>
      </ul>
      {purchases.map((purchase, i) => (
        <Link className='purchase' to='#' key={i}>
          <Col12 date={purchase.date} payer={purchase.payer} />
          <div className='col12-xl'>
            <Col12 date={purchase.date} payer={purchase.payer} />
          </div>
          <p className='col-3'>{purchase.description}</p>
          <p className='col-4 font-medium'>{'$' + purchase.amount}</p>
        </Link>
      ))}
    </div>
  );
}
