// Common card template for tournament card, team card, player card, etc

import Card from './Card';
import './CardTemplate.scss';

export default function CardTemplate({
  header,
  className,
  cardBtnText,
  cardBtnOnClick,
  icon,
  name,
  firstCol,
  secondCol,
  children,
  editBtn,
  deleteBtn,
}) {
  return (
    <Card
      header={header}
      className={'card-template ' + className}
      btnText={cardBtnText}
      onClick={cardBtnOnClick}
    >
      <div className='header block'>
        <div
          className='bg-image wh-128'
          style={{
            backgroundImage: `url(${icon})`,
          }}
        />
        <div className='details-buttons-wrap'>
          <div className='details'>
            <h4>{name}</h4>
            <div className='info'>
              <div className='first-col'>{firstCol}</div>
              <div className='second-col'>{secondCol}</div>
            </div>
          </div>
          <div className='buttons'>
            {editBtn && <button className='edit small bw'>Edit</button>}
            {deleteBtn && (
              <button className='delete small danger'>Delete</button>
            )}
          </div>
        </div>
      </div>
      <div className='children-wrap'>{children}</div>
    </Card>
  );
}
