// Player details interface

import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { Context } from '../App';
import moment from 'moment';
import TournamentsList from './TournamentsList';
import CardTemplate from './CardTemplate';
import Gallery from './Gallery';
import { teams, UsersRoles } from '../mock/data';
import Tabs from './Tabs';

export default function PlayerCard({ player }) {
  const navigate = useNavigate();
  const { user } = useContext(Context);

  const {
    icon,
    name,
    team,
    dateOfBirth,
    cardNum,
    cardExp,
    phone,
    email,
    idImage,
    birthCertificateImage,
    schoolReportCardImage,
  } = player;

  const userIsPlayer = user.role === UsersRoles.PLAYER;
  const userIsAdmin = user.role === UsersRoles.ADMIN;
  const userIsCoach = user.role === UsersRoles.COACH;

  return (
    <CardTemplate
      header='Player'
      className='player-card'
      cardBtnText={userIsPlayer ? undefined : 'Back'}
      cardBtnOnClick={userIsPlayer ? undefined : () => navigate(-1)}
      icon={icon}
      name={name}
      firstCol={<></>}
      secondCol={
        <>
          <p>
            <span className='gray-500 font-medium'>Date of birth: </span>
            {moment(dateOfBirth).format('MMM D, YY')}
          </p>
          <p>
            <span className='gray-500 font-medium'>Team: </span>
            {teams[team].name}
          </p>
          {cardNum && cardExp && (
            <p>
              <span className='gray-500 font-medium'>Card #</span>
              {cardNum} <span className='gray-500 font-medium'>exp. </span>
              {moment(cardExp).format('MMM D, YY')}
            </p>
          )}
          {!(cardNum && cardExp) && !userIsPlayer && (
            <p>
              <span className='gray-500 font-medium'>Card: </span>
              <span className='danger upper font-medium'>No card</span>
            </p>
          )}
          <p>
            <span className='gray-500 font-medium'>Phone: </span>
            {phone}
          </p>
          <p>
            <span className='gray-500 font-medium'>e-mail: </span>
            {email}
          </p>
          {!(cardNum && cardExp) && userIsPlayer && (
            <div className='buy-card-btn-wrap'>
              <button className='small bw'>Buy card</button>
            </div>
          )}
        </>
      }
      deleteBtn={userIsAdmin || userIsCoach}
      editBtn
    >
      {!userIsPlayer && (
        <div className='block'>
          <Gallery
            images={[
              { title: 'ID', image: idImage },
              { title: 'Birth certificate', image: birthCertificateImage },
              { title: 'School report card', image: schoolReportCardImage },
            ]}
          />
        </div>
      )}
      {userIsPlayer && (
        <Tabs
          className='player-card-tabs'
          tabs={[
            {
              title: 'Documents',
              hash: '#documents',
              component: (
                <div className='block'>
                  <Gallery
                    images={[
                      { title: 'ID', image: idImage },
                      {
                        title: 'Birth certificate',
                        image: birthCertificateImage,
                      },
                      {
                        title: 'School report card',
                        image: schoolReportCardImage,
                      },
                    ]}
                  />
                </div>
              ),
            },
            {
              title: 'Tournaments',
              hash: '#tournaments',
              component: (
                <div className='block'>
                  <TournamentsList teamId={team} />
                </div>
              ),
            },
          ]}
        />
      )}
    </CardTemplate>
  );
}
