// Teams list

import { Link } from 'react-router-dom';
import { useState, useContext } from 'react';
import { Context } from '../App';
import { UsersRoles } from '../mock/data';
import { teams, tournaments } from '../mock/data';
import TeamTournStatusPaid from './TeamTournStatusPaid';
import Select from './Select';
import './TeamsList.scss';

const locations = [
  'All locations',
  ...new Set(Object.entries(teams).map(([key, value]) => value.location)),
];

const sports = [
  'All sports',
  ...new Set(Object.entries(teams).map(([key, value]) => value.sport)),
];

export default function TeamsList({ tournamentId, controls, idFilter }) {
  const [location, setLocation] = useState(locations[0]);
  const [sport, setSport] = useState(sports[0]);
  const { user } = useContext(Context);

  const userIsAdmin = user.role === UsersRoles.ADMIN;
  const userIsCoach = user.role === UsersRoles.COACH;
  const userIsPlayer = user.role === UsersRoles.PLAYER;
  const addCol = tournamentId && userIsAdmin;

  return (
    <div className={'teams-list' + (addCol ? ' add-col' : '')}>
      {controls && (
        <div className='controls'>
          <ul className='filters'>
            <li>
              <Select
                options={locations}
                value={location}
                onChange={setLocation}
              />
            </li>
            <li>
              <Select options={sports} value={sport} onChange={setSport} />
            </li>
          </ul>
          <button className='small bw'>Add team</button>
        </div>
      )}
      <ul className='header tab-header'>
        <li className='col-1'></li>
        <li className='col-2'>Team</li>
        <li className='col-3'>Location</li>
        <li className='col-23'>Team, Location</li>
        <li className='col-4'>Sport</li>
        <li className='col-5'>Division</li>
        <li className='col-6'>
          Players
          <br />
          number
        </li>
        <li className='col-456'>Sport, Division, Players number</li>
        {addCol && (
          <ul className='additional tab-header'>
            <li className='status'>Status</li>
            <li className='paid'>Paid</li>
            <li className='status-paid-wrap'>Status, Paid</li>
          </ul>
        )}
      </ul>
      {Object.entries(teams)
        .map(([key, value]) => ({ id: key, ...value }))
        .filter((team) => {
          if (location === locations[0]) return true;
          if (team.location === location) return true;
          return false;
        })
        .filter((team) => {
          if (sport === sports[0]) return true;
          if (team.sport === sport) return true;
          return false;
        })
        .filter((team) => {
          if (!idFilter) return true;
          if (idFilter.includes(team.id)) return true;
          return false;
        })
        .map((team, i) => {
          const status = tournaments[tournamentId]?.teams[team.id]?.status;
          const paid = tournaments[tournamentId]?.teams[team.id]?.paid;
          const isDisabled =
            userIsPlayer || (userIsCoach && team.coach !== user.id);

          return (
            <Link
              to={'/teams/' + team.id}
              className={'team' + (isDisabled ? ' disabled' : '')}
              onClick={isDisabled ? (e) => e.preventDefault() : undefined}
              key={i}
            >
              <div
                className='bg-image wh-64'
                style={{
                  backgroundImage: `url(${team.icon})`,
                }}
              />
              <p className='col-2 font-medium upper'>{team.name}</p>
              <p className='col-3'>{team.location}</p>
              <p className='col-23'>
                {team.name}
                <br />
                {team.location}
              </p>
              <p className='col-4'>{team.sport}</p>
              <p className='col-5'>{team.division}</p>
              <p className='col-6'>{team.players.length + ' players'}</p>
              <p className='col-456'>
                {team.sport}, {team.division}
                <br />
                {team.players.length + ' players'}
              </p>
              {addCol && <TeamTournStatusPaid status={status} paid={paid} />}
            </Link>
          );
        })}
    </div>
  );
}
