// Working with Gallery component. Showing an image as a full-screen modal window

import { Link } from 'react-router-dom';
import './ImageModal.scss';

export default function ImageModal({ show, image, onClose }) {
  return (
    <div className={'image-modal modal' + (show ? ' show' : '')}>
      <div
        className='bg-image'
        style={{
          backgroundImage: `url(${image})`,
        }}
      />
      <button className='close-btn white small' onClick={onClose}>
        &#xf00d;
      </button>
      <Link
        to={image}
        target='_blank'
        onClick={(e) => e.stopPropagation()}
        className='download-btn button bw small'
        download={image?.split('/').pop()}
      >
        Download
      </Link>
    </div>
  );
}
