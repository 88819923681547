// Team details interface

import { Link, useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { Context } from '../App';
import CardTemplate from './CardTemplate';
import TournamentsList from './TournamentsList';
import Tabs from './Tabs';
import PlayersList from './PlayersList';
import { coaches, UsersRoles } from '../mock/data';
import './TeamCard.scss';

export default function TeamCard({ team }) {
  const navigate = useNavigate();
  const { user } = useContext(Context);

  const { id, icon, name, location, division, coach } = team;

  const userIsAdmin = user.role === UsersRoles.ADMIN;
  const userIsCoach = user.role === UsersRoles.COACH;

  return (
    <CardTemplate
      header='Team'
      className='team-card'
      cardBtnText='Back'
      cardBtnOnClick={() => navigate(-1)}
      icon={icon}
      name={name}
      firstCol={<p className='fa-location font-16'>{location}</p>}
      secondCol={
        <>
          <p className='font-16'>
            <span className='gray-500 font-medium'>Division:</span> {division}
          </p>
          <Link to={'/coaches/' + coach} className='coach font-16'>
            <span className='gray-500 font-medium'>Coach: </span>
            {coaches[coach].name}
          </Link>
          <div className='buttons'>
            {userIsAdmin && <button className='small bw'>Discount</button>}
            {(userIsAdmin || userIsCoach) && (
              <button className='small bw'>Invite players</button>
            )}
          </div>
        </>
      }
      deleteBtn={userIsAdmin}
      editBtn
    >
      <Tabs
        className='team-card-tabs'
        tabs={[
          {
            title: 'Players',
            hash: '#players',
            component: (
              <div className='block'>
                <PlayersList teamId={id} />
              </div>
            ),
          },
          {
            title: 'Tournaments',
            hash: '#tournaments',
            component: (
              <div className='block'>
                <TournamentsList teamId={id} />
              </div>
            ),
          },
        ]}
      />
    </CardTemplate>
  );
}
