// Players list

import { Link } from 'react-router-dom';
import moment from 'moment';
import MarkedText from './MarkedText';
import { players, teams } from '../mock/data';
import './PlayersList.scss';

export default function PlayersList({ teamId }) {
  return (
    <div className='players-list'>
      <ul className='header tab-header'>
        <li className='col-1'></li>
        <li className='col-2'>Name</li>
        <li className='col-3'>Date of birth</li>
        <li className='col-23'>Name, Date of birth</li>
        <li className='col-4'>Card</li>
        <li className='col-5'>Docs</li>
      </ul>
      {teams[teamId].players
        .sort((a, b) => (players[a].name > players[b].name ? 1 : -1))
        .map((playerId, i) => {
          const {
            name,
            icon,
            dateOfBirth,
            cardNum,
            cardExp,
            idImage,
            birthCertificateImage,
            schoolReportCardImage,
          } = players[playerId];

          return (
            <Link to={'/players/' + playerId} className='player' key={i}>
              <div
                className='bg-image wh-64'
                style={{
                  backgroundImage: `url(${icon})`,
                }}
              />
              <p className='col-2'>
                <span className='font-medium upper'>{name}</span>
                <br />
                <span className='dob'>
                  {moment(dateOfBirth).format('MMM D, YY')}
                  <br />
                </span>
              </p>
              <p className='col-3'>{moment(dateOfBirth).format('MMM D, YY')}</p>
              {cardNum && cardExp && (
                <p className='col-4'>
                  {'#' + cardNum}
                  <br />
                  {'exp. ' + moment(cardExp).format('MMM D, YY')}
                </p>
              )}
              {!(cardNum && cardExp) && (
                <p className='col-4 no-card danger upper font-medium'>
                  No card
                </p>
              )}
              <div className='col-5'>
                <div className='marked-text-wrap'>
                  <MarkedText mark={!!idImage} text='ID' />
                  <MarkedText
                    mark={!!birthCertificateImage}
                    text='Birth certificate'
                  />
                  <MarkedText
                    mark={!!schoolReportCardImage}
                    text='Report card'
                  />
                </div>
              </div>
            </Link>
          );
        })}
    </div>
  );
}
