// Tournaments list

import { useState, useContext } from 'react';
import { Context } from '../App';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { tournaments, UsersRoles } from '../mock/data';
import TeamTournStatusPaid from './TeamTournStatusPaid';
import Select from './Select';
import './TournamentsList.scss';

const locations = [
  'All locations',
  ...new Set(Object.entries(tournaments).map(([key, value]) => value.location)),
];

const sports = [
  'All sports',
  ...new Set(Object.entries(tournaments).map(([key, value]) => value.sport)),
];

const times = [
  'All time',
  ...(() => {
    const monthsList = [];

    for (let i = 0; i < 12; i++)
      monthsList.push(moment().add(i, 'months').format('MMMM YYYY'));

    return monthsList;
  })(),
];

function Col23({ name, location }) {
  return (
    <>
      <p className='col-2 font-medium upper'>{name}</p>
      <p className='col-3'>{location}</p>
    </>
  );
}

export default function TournamentsList({ teamId, controls }) {
  const [location, setLocation] = useState(locations[0]);
  const [sport, setSport] = useState(sports[0]);
  const [time, setTime] = useState(times[0]);
  const { user } = useContext(Context);

  const userIsAdmin = user.role === UsersRoles.ADMIN;
  const userIsCoach = user.role === UsersRoles.COACH;
  const userIsPlayer = user.role === UsersRoles.PLAYER;
  const addCol = teamId && (userIsAdmin || userIsCoach);

  return (
    <div className={'tournaments-list' + (addCol ? ' add-col' : '')}>
      {controls && (
        <div className='controls'>
          <ul className='filters'>
            <li>
              <Select
                options={locations}
                value={location}
                onChange={setLocation}
              />
            </li>
            <li>
              <Select options={sports} value={sport} onChange={setSport} />
            </li>
            <li>
              <Select options={times} value={time} onChange={setTime} />
            </li>
          </ul>
          <button className='small bw'>Add tournament</button>
        </div>
      )}
      <ul className='header tab-header'>
        <li className='col-1'></li>
        <li className='col-2'>Tournament</li>
        <li className='col-3'>Location</li>
        <li className='col-23-wrap'>Tournament, Location</li>
        <li className='col-4'>Sport</li>
        <li className='col-5'>Type, Division</li>
        <li className='col-45-wrap'>Sport, Type, Division</li>
        <li className='col-6'>From, To</li>
        <li className='col-456-wrap'>From - To, Sport, Type, Division</li>
        {addCol && (
          <ul className='additional tab-header'>
            <li className='status'>Status</li>
            <li className='paid'>Paid</li>
            <li className='status-paid-wrap'>Status, Paid</li>
          </ul>
        )}
      </ul>
      {Object.entries(tournaments)
        .map(([key, value]) => ({ id: key, ...value }))
        .filter((tourn) => {
          if (location === locations[0]) return true;
          if (tourn.location === location) return true;
          return false;
        })
        .filter((tourn) => {
          if (sport === sports[0]) return true;
          if (tourn.sport === sport) return true;
          return false;
        })
        .filter((tourn) => {
          if (time === times[0]) return true;
          if (time === moment(tourn.dateFrom).format('MMMM YYYY')) return true;
          if (time === moment(tourn.dateTo).format('MMMM YYYY')) return true;
          return false;
        })
        .filter((tourn) => {
          if (userIsAdmin) return true;
          if ((userIsCoach || userIsPlayer) && tourn.teams[teamId]) return true;
          return false;
        })
        .map((tourn, i) => {
          const status = tourn.teams[teamId]?.status;
          const paid = tourn.teams[teamId]?.paid;

          return (
            <Link
              className='tournament'
              to={'/tournaments/' + tourn.id}
              key={i}
            >
              <div
                className='bg-image wh-64'
                style={{
                  backgroundImage: `url(${tourn.icon})`,
                }}
              />
              <Col23 name={tourn.name} location={tourn.location} />
              <div className='col-23-wrap'>
                <Col23 name={tourn.name} location={tourn.location} />
              </div>
              <p className='col-4'>{tourn.sport}</p>
              <div className='col-4-8-wrap'>
                <p className='col-78'>
                  {`${moment(tourn.dateFrom).format('MMM D, YY')} - ${moment(
                    tourn.dateTo
                  ).format('MMM D, YY')}`}
                </p>
                <p className='col-4'>{tourn.sport}</p>
                <p className='col-5'>{tourn.type}</p>
                <p className='col-6'>{tourn.division}</p>
              </div>
              <div className='col-78-wrap'>
                <p className='col-7'>
                  {moment(tourn.dateFrom).format('MMM D, YY')}
                </p>
                <p className='col-8'>
                  {moment(tourn.dateTo).format('MMM D, YY')}
                </p>
              </div>
              {addCol && (
                <TeamTournStatusPaid
                  status={status}
                  paid={paid}
                  role={user.role}
                />
              )}
            </Link>
          );
        })}
    </div>
  );
}
