// Component shows menu, can be used anywhere

import { useEffect, useRef } from 'react';
import './ContextMenu.scss';

export default function ContextMenu({
  show,
  onClose,
  onSelect,
  options,
  className,
}) {
  const menuRef = useRef(null);

  // This is for catching outside clicks to close the menu
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (show) {
      document.addEventListener('mousedown', handleClickOutside);
    } else document.removeEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [show]);

  return (
    <ul
      className={
        'context-menu' +
        (show ? ' show' : '') +
        (className ? ' ' + className : '')
      }
      ref={menuRef}
    >
      {options.map((option, i) => (
        <li
          key={i}
          onClick={(e) => {
            e.stopPropagation();
            onSelect(i);
            onClose();
          }}
        >
          {option}
        </li>
      ))}
    </ul>
  );
}
