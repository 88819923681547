// Coach details interface

import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { Context } from '../App';
import CardTemplate from './CardTemplate';
import TeamsList from './TeamsList';
import Tabs from './Tabs';
import Gallery from './Gallery';
import { UsersRoles } from '../mock/data';

export default function CoachCard({ coach }) {
  const navigate = useNavigate();
  const { user } = useContext(Context);

  const { id, icon, name, phone, email, idImage, teams } = coach;

  const userIsCoach = user.role === UsersRoles.COACH;
  const userIsAdmin = user.role === UsersRoles.ADMIN;

  return (
    <CardTemplate
      header='Coach'
      className='coach-card'
      cardBtnText={userIsCoach ? undefined : 'Back'}
      cardBtnOnClick={userIsCoach ? undefined : () => navigate(-1)}
      icon={icon}
      name={name}
      firstCol={<></>}
      secondCol={
        <>
          <p className='font-16'>
            <span className='gray-500 font-medium'>Phone:</span> {phone}
          </p>
          <p className='font-16 coach'>
            <span className='gray-500 font-medium'>e-mail:</span> {email}
          </p>
        </>
      }
      deleteBtn={userIsAdmin}
      editBtn
    >
      <Tabs
        className='coach-card-tabs'
        tabs={[
          {
            title: 'Teams',
            hash: '#teams',
            component: (
              <div className='block'>
                <TeamsList idFilter={teams} />
              </div>
            ),
          },
          {
            title: 'Documents',
            hash: '#documents',
            component: (
              <div className='block'>
                <Gallery images={[{ title: 'ID', image: idImage }]} />
              </div>
            ),
          },
        ]}
      />
    </CardTemplate>
  );
}
