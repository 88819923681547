// Player page

import { useLoaderData } from 'react-router-dom';
import Layout from '../components/Layout';
import PlayerCard from '../components/PlayerCard';

export default function PagePlayer() {
  const player = useLoaderData();

  return (
    <Layout className='page-player'>
      <PlayerCard player={player} />
    </Layout>
  );
}
