// Text with check or cross marks

import './MarkedText.scss';

export default function MarkedText({ text, mark }) {
  return (
    <div className='marked-text'>
      <p className={'mark' + (mark ? ' green' : ' red')}>
        {mark ? '\u{f00c}' : '\u{f00d}'}
      </p>
      <p className='text'>{text}</p>
    </div>
  );
}
