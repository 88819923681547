// Login card

import { useContext } from 'react';
import { Context } from '../App';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Card from './Card';
import Input from './Input';
import { emailRegExp } from '../utils/misc';
import { users } from '../mock/data';
import './LoginCard.scss';

const initialValues = {
  email: '',
  password: '',
};

export default function LoginCard() {
  const navigate = useNavigate();
  const { setUser } = useContext(Context);

  const validationSchema = new Yup.ObjectSchema({
    email: Yup.string()
      .matches(emailRegExp, 'Invalid email')
      .required('Required'),
    password: Yup.string().required('Required'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setFieldError }) => {
      const user = Object.entries(users)
        .map(([key, value]) => ({ id: key, ...value }))
        .find((user) => user.email === values.email);

      if (!user) {
        setFieldError('email', 'Invalid username or password');
        return;
      }

      setUser(user);
      navigate(user.redirect);
    },
  });

  return (
    <Card header='Login' className='login-card'>
      <form className='login-form' onSubmit={formik.handleSubmit}>
        <Input
          name='email'
          placeholder='E-mail'
          {...formik.getFieldProps('email')}
          error={
            formik.touched.email && formik.errors.email
              ? formik.errors.email
              : null
          }
        />
        <Input
          name='password'
          placeholder='Password'
          type='password'
          {...formik.getFieldProps('password')}
          error={
            formik.touched.password && formik.errors.password
              ? formik.errors.password
              : null
          }
        />
        <button className='signin-btn' type='submit'>
          Sign in
        </button>
      </form>
    </Card>
  );
}
