// Card component

import './Card.scss';

export default function Card({
  header,
  className,
  children,
  btnText,
  onClick,
}) {
  return (
    <div className={'card' + (className ? ' ' + className : '')}>
      <div className='card-top'>
        <h5 className='header'>{header}</h5>
        {btnText && (
          <button
            className='back-button fa-left-arrow white small'
            onClick={onClick}
          >
            {btnText}
          </button>
        )}
      </div>
      <div className='card-body'>{children}</div>
    </div>
  );
}
