// Login page

import Layout from '../components/Layout';
import LoginCard from '../components/LoginCard';

export default function PageLogin() {
  return (
    <Layout screenSized className='page-login'>
      <LoginCard />
    </Layout>
  );
}
